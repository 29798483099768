export default function filterAlbums(menuList, menuSectionsToRemove) {
  return menuList.filter((section, index) => {
    return !menuSectionsToRemove
      .map(menuSectionToRemove => {
        return section?.section?.name.trim().toLowerCase() === menuSectionToRemove.trim().toLowerCase()
      })
      .includes(true)
  })
}

// I.e filterMenu(res.data[0].inventory, options.removeSections)

// Used on the Menu Page to Filter out any menus you want to remove
// I.e  menuList = [{name:catering,inventory:[{},{}]},{name:burgers,inventory:[{},{}]},{name:drinks,inventory:[{},{}]}]
// I.e  menuSectionsToRemove = ["Catering", "Burgers"],
// returns [{name:drinks,inventory:[{},{}]}]

// first loops through the menuList with filter remove any of the menu sections names that
// match anything in the menuSectionsToRemove to remove
// it is not case senstive
// can only do this at the top level of sections.
