import React from 'react'

const Linkedin = ({ color, height, width, style }) => (
  <svg
    style={style}
    width={width}
    height={height}
    className="LinkedinLogo"
    viewBox="0 0 101 100"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="linkedin">
      <path
        d="M64.6042 68.4416L64.6042 68.4375L64.6292 68.4375L64.6292 31.7625C64.6292 13.8208 60.7667 0 39.7917 0C29.7083 0 22.9417 5.53333 20.1792 10.7792L19.8875 10.7792L19.8875 1.675L0 1.675L0 68.4375L20.7083 68.4375L20.7083 35.3792C20.7083 26.675 22.3583 18.2583 33.1375 18.2583C43.7583 18.2583 43.9167 28.1917 43.9167 35.9375L43.9167 68.4417L64.6042 68.4416Z"
        transform="translate(35.37091 31.558228)"
        id="Shape"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M3.72529e-07 0L20.7333 0L20.7333 66.7625L0 66.7625L3.72529e-07 0Z"
        transform="translate(1.6499634 33.237427)"
        id="Shape"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
      <path
        d="M12.0083 0C5.37917 0 0 5.37917 0 12.0083C0 18.6375 5.37917 24.1292 12.0083 24.1292C18.6375 24.1292 24.0167 18.6375 24.0167 12.0083C24.0125 5.37917 18.6333 0 12.0083 0L12.0083 0Z"
        id="Shape"
        fill={color}
        fillRule="evenodd"
        stroke="none"
      />
    </g>
  </svg>
)

Linkedin.defaultProps = {
  color: 'white',
  height: '16px',
  width: '16px'
}

export default Linkedin
