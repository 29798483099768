/**
 *
 * selects the title based on the string input
 *
 *
 * @param {string} text - string of text that you want to restrict
 * @param {number} restrictCharacters - the amount of characters you want to restrict the text by
 *
 */

export default (text, restrictCharacters) => {
  return restrictCharacters ? `${text.substring(0, restrictCharacters)}...` : text
}
