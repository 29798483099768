import React from 'react'
import Link from 'gatsby-link'
import { Flex } from '@theme-ui/components'
import generateLink from '../../../helpers/generateLink'
import checkIfExternalLink from '../../../helpers/checkIfExternalLink'
import shortid from 'shortid'

export default function MultiButtons({ isMultiLocationSite, links, city, variant }) {
  return (
    <Flex variant={`${variant ? variant : 'multiButtonsV1'}.container`} className={`multiButtonContainer`}>
      {links.map((link, index) => {
        if (link.dropdownLinks || !link.ctaLink) {
          // if there is no link or if there is dropdowns then don't render
        } else {
          return (
            <Link
              key={shortid.generate()}
              target={checkIfExternalLink(link.ctaLink) ? '_blank' : ''}
              rel={checkIfExternalLink(link.ctaLink) ? 'noreferrer' : ''}
              to={`${generateLink(link.ctaLink, isMultiLocationSite, city)}`}
            >
              {link.ctaName}
            </Link>
          )
        }

        return ''
      })}
    </Flex>
  )
}

// Notes
// takes an array of button links
// array of button names
// and renders a row of buttons.
