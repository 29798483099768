import React from 'react'

export default function YouTube({ color, height, width, style }) {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path d="M0 0L24 0L24 24L0 24L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use xlinkHref="#path_1" />
        </clipPath>
      </defs>
      <g id="youtube">
        {/* <path
          d='M0 0L24 0L24 24L0 24L0 0Z'
          id='Background'
          fill='none'
          fillRule='evenodd'
          stroke='none'
        /> */}
        <g clipPath="url(#mask_1)">
          <path
            fill={color}
            d="M23.4693 2.42901L23.4993 2.62501C23.2093 1.59601 22.4263 0.802014 21.4313 0.513014L21.4103 0.508014C19.5393 1.38283e-05 12.0103 1.38283e-05 12.0103 1.38283e-05C12.0103 1.38283e-05 4.50029 -0.00998616 2.61029 0.508014C1.59629 0.802014 0.812294 1.59601 0.527294 2.60401L0.522294 2.62501C-0.176706 6.27601 -0.181706 10.663 0.553294 14.572L0.522294 14.374C0.812294 15.403 1.59529 16.197 2.59029 16.486L2.61129 16.491C4.48029 17 12.0113 17 12.0113 17C12.0113 17 19.5203 17 21.4113 16.491C22.4263 16.197 23.2103 15.403 23.4953 14.395L23.5003 14.374C23.8183 12.676 24.0003 10.722 24.0003 8.72601C24.0003 8.65301 24.0003 8.57901 23.9993 8.50501C24.0003 8.43701 24.0003 8.35601 24.0003 8.27501C24.0003 6.27801 23.8183 4.32401 23.4693 2.42901L23.4693 2.42901ZM9.60829 12.151L9.60829 4.85801L15.8743 8.51001L9.60829 12.151Z"
            transform="translate(-0.0002937317 3.4999866)"
            id="Shape"
            fillRule="evenodd"
            stroke="none"
          />
        </g>
      </g>
    </svg>
  )
}

YouTube.defaultProps = {
  color: 'white',
  height: '16px',
  width: '16px'
}
