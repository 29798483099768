import slugify from 'slugify'
import useCurrentLocationNameAndSlug from '../hooks/useCurrentLocationNameAndSlug'

export default (city, ctaLink) => {
  const {
    // currentLocationName,
    currentLocationSlug
  } = useCurrentLocationNameAndSlug()

  return currentLocationSlug
    ? `/${slugify(currentLocationSlug, { lower: true })}/${slugify(ctaLink, {
        lower: true
      })}`
    : ''
}
