import React, { useEffect, useState } from 'react'
import shortid from 'shortid'
let axios = require('axios')
let jsonpAdapter = require('axios-jsonp')

// For Testing DEV BUSINESS ID
const DEV_BUSINESS_ID = ''
// const DEV_BUSINESS_ID = 'bzn-T2vOUVwXQ-SvOoeuX64BGA'
const DEV_API_BASE = 'https://maindiscovery.dev.gonation.com'
const PRODUCTION_API_BASE = 'https://data.gonation.com'
const APIBASE = DEV_BUSINESS_ID ? DEV_API_BASE : PRODUCTION_API_BASE // this is a manaul toggle variable

export default function ShoutHOC(gonationID, Component, props) {
  const HOC = props => {
    const [shoutData, setShoutData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
      if (gonationID) {
        axios({
          url: `${APIBASE}/profile/shoutsnew/${gonationID}`,
          adapter: jsonpAdapter
        })
          .then(res => {
            setShoutData(res.data)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }, [])

    return !isLoading ? <Component key={shortid.generate()} {...props} shoutData={shoutData} /> : ''
  }

  return HOC
}

// takes in gonation id and shout data
// this is a higher order component that gives any component shout data as a prop. by fetching the data for it.
