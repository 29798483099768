import checkIfExternalLink from './checkIfExternalLink'
import slugify from './slugifyLower'
import useCurrentLocationNameAndSlug from '../hooks/useCurrentLocationNameAndSlug'

export default (link, isMultiLocationSite, city) => {
  const {
    // currentLocationName,
    currentLocationSlug
  } = useCurrentLocationNameAndSlug()

  if (link) {
    if (checkIfExternalLink(link)) {
      // ? ==== EXTERNAL LINKS ====

      const linkIsTextOrPhoneorEmail = link.includes('sms') || link.includes('tel') || link.includes('mailto')
      const requiresHttpInjection = !link.includes('https') && !link.includes('http')
      if (linkIsTextOrPhoneorEmail) {
        return link
      }
      if (requiresHttpInjection) {
        return 'https://' + link
      }
      return link // i.e return (https://google.com) // for external links
    } else {
      // ? ==== INTERNAL LINKS ====

      const isMultiLocationLandingPage = slugify(currentLocationSlug) === slugify(link)

      if (currentLocationSlug && isMultiLocationLandingPage) {
        return `/${slugify(currentLocationSlug)}` // multi-location landing page
      } else {
        return isMultiLocationSite
          ? `/${slugify(currentLocationSlug)}/${slugify(link)}` // i.e returns (milford/menu) // for multi location links other than landing page
          : `/${slugify(link)}` //  i.e returns (menu) // for normal single location links
      }
    }
  } else if (link === '') {
    return '/' // if link empty string return to home
  }
}
