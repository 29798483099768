import React from 'react'
import { Flex, Heading, Text } from '@theme-ui/components'
import ContentBoxes from '../../ContentBoxes/v3/ContentBoxes'

export default function Modal(props) {
  const {
    variantName,
    title,
    subtitle,
    text,
    // images,
    // ctaLinks,
    toggleModal
    // texts,
  } = props

  const variant = variantName ? variantName : 'modalV1'
  return (
    <Flex variant={`${variant}.modalContainer`}>
      <Flex
        variant={`${variant}.modalBackground`}
        onClick={() => {
          toggleModal()
        }}
      />

      <Flex variant={`${variant}.content`}>
        {title && (
          <Heading as="h3" variant={`${variant}.modalTitle`}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Heading as="h4" variant={`${variant}.modalSubtitle`}>
            {subtitle}
          </Heading>
        )}
        {text && <Text variant={`${variant}.modalText`}>{text}</Text>}

        <ContentBoxes {...props} />

        {/* this needs refactoring into contentboxes */}

        {/* {images && (
          <Flex variant={`${variant}.modalImages`}>
            {images.map((image, index) => {
              return (
                <Link
                  key={shortid.generate()}
                  href={ctaLinks[index]}
                  variant={`${variant}.modalCTALink`}
                  target='_blank'
                  rel='noreferrer'>
                  <Image
                    variant={`${variant}.modalImage`}
                    src={image}
                    alt='content'
                  />
                </Link>
              )
            })} */}
        {/* </Flex> */}
        {/* )} */}
      </Flex>
    </Flex>
  )
}
