/**
 *
 * *This takes all the stories and filters the one it wants by id
 * Then returns and object of data for that story else return empy object
 *
 * @param {string} storyId - the id of the story from GN
 * @param {array} allGeneral - All Stories in an Array.
 * @param {boolean} isArrayWithinArray - (UnderWorks) nested block of arrays in arrays
 */

const createImagesArray = (images, size) => {
  if (!images && !images?.length) return []
  return images.map(image => {
    if (image.cloudinaryId) {
      return `https://res.cloudinary.com/gonation/w_${size || 1800}/q_auto/f_auto/${image.cloudinaryId}`
    } else {
      return ''
    }
  })
}

export default (storyId, allGeneral, storyTag, size = 1800) => {
  if (allGeneral) {
    const storyByTag = allGeneral.filter(story => story?.tags && story?.tags.includes(storyTag))[0] // !should only ever return one element in a array
    const storyById = allGeneral.filter(story => story.id === storyId)[0] // !should only ever return one element in a array
    const story = storyTag ? storyByTag : storyById
    if (story) {
      // returns a object of the story data
      const imageArray = createImagesArray(story.media, size)

      return {
        titles: story?.title,
        subtitles: story?.subtitle,
        texts: story.bodyHTML ? (story.bodyHTML.replace(/\s/g, '') !== '<p></p>' ? story.bodyHTML : '') : '', // regex replace needed to remove spaces and tabs
        textType: 'html', // needed to determine
        images: imageArray,
        ctaNames: story?.ctaLink?.title,
        ctaLinks: story?.ctaLink?.url,
        links: story?.ctaLinks
      }
    } else {
      console.log('Story Could Not Be Found By Id')
      return {}
    }
  } else {
    return {}
  }
}
