import React from 'react'
import { Heading, Text, Flex, Box } from 'theme-ui'
export default function TextContent({ variantName, title, subtitle, text, textType }) {
  return (
    <Flex variant={`${variantName}.textContent`} className="textContent">
      {title && (
        <Heading as="h3" variant={`${variantName}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h4" variant={`${variantName}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}

      {text && textType !== 'html' ? (
        <Text as="p" variant={`${variantName}.text`} className="text">
          {text}
        </Text>
      ) : (
        ''
      )}

      {text && textType === 'html' ? (
        <Box variant={'bodyHTMLStyles'} className="text" dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        ''
      )}
    </Flex>
  )
}
