// ? === 3d Party ===
import React from 'react'
import { Flex } from 'theme-ui'

// ? === Local ===
import ContentBox from './ContentBox'
import HardCodedBoxes from './HardCodedBoxes'
import componentOptionsSelector from '../../../helpers/componentOptionsSelector'
import generateLink from '../../../helpers/generateLink'

// ? =================
// ? === Component ===
// ? =================

export default function ContentBoxes(props) {
  const {
    options,
    variantName,
    city,
    // configData,
    // businessData,
    poweredImages,
    isMultiLocationSite,
    locationIndex,
    allGeneral
  } = props

  const storyIds = options?.storyId?.[locationIndex]
  const storyTag = options?.storyTag?.[locationIndex]
  const usingStories = Boolean(storyIds || storyTag)
  const storyData = usingStories ? (storyTag ? storyTag : storyIds) : undefined
  const storyObjects = storyData?.map((storyId, index) => {
    return componentOptionsSelector(options, locationIndex, storyId, allGeneral, storyTag?.[index])
  })

  const StoryBoxes = () => {
    return storyObjects.map(({ titles, subtitles, textType, texts, images, ctaNames, ctaLinks }) => {
      const ctaLink = ctaLinks ? generateLink(ctaLinks, isMultiLocationSite, city) : ''

      return (
        <ContentBox
          variantName={variantName}
          ctaLink={ctaLink}
          ctaName={ctaNames}
          image={images?.[0]}
          title={titles}
          subtitle={subtitles}
          text={texts}
          textType={textType}
          locationIndex={locationIndex}
          poweredImages={poweredImages}
        />
      )
    })
  }
  return (
    <Flex variant={`${variantName}.contentBoxes`} className="contentBoxes">
      {usingStories ? StoryBoxes() : <HardCodedBoxes {...props} />}
    </Flex>
  )
}
